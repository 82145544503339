import React from "react";
import resumeFile from "../documents/resume.pdf";

const Resume = () => {
  const educationDetails = [
    {
      yearRange: "Nov 2023 till Present",
      title: "Masters Life Coaching and Masters NLP",
      place: "INLP Center, California",
      desc: " ",
    },
    {
      yearRange: "2016",
      title: "Neuro linguistic programing coach",
      place: "Inspire 360 London-UK",
      desc: " ",
    },
    {
      yearRange: "2019 - 2020",
      title: "Certificate of foundation of positive psychology",
      place: "UPENN",
      desc: " ",
    },
    {
      yearRange: "1999-2005 ",
      title: "MBA with emphasis in finance and marketing (High distinction GPA 4/4)",
      place: "Lebanese American University",
      desc: " ",
    },
    {
      yearRange: "1995 -1996",
      title: "Teaching Diploma in Science education for secondary classes",
      place: "American University of Beirut",
      desc: " ",
    },
    {
      yearRange: "1992-1996",
      title: "Bachelor of Science in Biology",
      place: "American University of Beirut",
      desc: " ",
    },
    {
        yearRange: "included below",
        title: "Professional Training",
        place: "-",
        desc: "TIME LINE THERAPY & HYPNOSIS, 2016,ADVANCED MICROSOFT EXCEL 2019, SOFT SKILLS SERI, BRAND COMMUNICATION WORKSHOP-2007, SPSS 2005, MARKETING STRATEGIES 2000",
      }
  
];
  const experienceDetails = [
    {
      yearRange: "June 2016 - current",
      title: "NEURO-LINGUISTIC PROGRAMMING (NLP) COACH AND TIME LINE THERAPIST ",
      place: "Freelance",
      desc: "Meeting with clients to discuss their problems and needs. Helping clients set short and long term goals while setting a clear plan or strategy. Using Time Line Therapy (TLT) to help clients cope with negative emotions such as Anger, Sadness, Fear, Hurt and Guilt that  can be extremely restrictive to their state of mind.  Assisting Clients through TLT and other NLP techniques to help improve their mental and emotional well-being, increase their personal and professional effectiveness, and achieve their goals.",
    },
    {
      yearRange: "Aug 2014- Oct 2021",
      title: "ASSISTANT TO THE VICE PRESIDENT OF  STUDENT DEVELOPMENT AND ENROLLMENT MANAGEMENT ",
      place: "LEBANESE AMERICAN UNIVERSITY (LAU), Beirut, Lebanon",
      desc: "	Reporting to the VP for SDEM and ensuring a smooth workflow. Supporting students in their academic and personal struggles.	Overseeing various SDEM projects .Coordinating with senior leadership (President, VPs, Deans, Department Chairs) and 90 staff reporting to the VPSDEM office.Collaborating across SDEM units to ensure smooth work operations and high quality of services and solve arising conflicts if need  be	Representing SDEM on several interdisciplinary committees such as commencement and student elections while playing a role in the planning and decision making processes as well as assessment of the respective events afterwards .Supervising five student assistants and overseeing the data analysis and compilation of the EXIT survey, NSSE, and Universum Surveys and coming up with recommendations.Producing semiannual Board of trustees reports and presentations.	Managing office expenses while monitoring budget.Creating a Standard Operating procedure manual for the administrative work of the office",
    },
    {
      yearRange: "Dec 2007 - Dec 2009",
      title: "MARKETING RESEARCH CONSULTANT ",
      place: "LEBANESE AMERICAN UNIVERSITY ",
      desc: "Preparing annual marketing plan in coordination with the marketing team and director.Collecting and analyzing data from different school departments. ●	Conducting focus group meetings with head of departments, faculty, staff and students. Assessing and optimizing marketing .●	Helping in the classification and selection of branding companies for LAU branding initiative.",
    },
    {
      yearRange: "Oct 2014 - Oct 2021,March 2002, March 2002  - Dec 2010, March 2002 -Dec 2010, March 2009 -Sep 2014",
      title: "CONSULTANT, FOUNDER, MEMBER OF THE BOARD, PRESIDENT OF SHIFAA JUNIOR CLUB  ",
      place: "SHIFAA (NGO for Healthcare), Beirut, Lebanon ",
      desc: "Planning and managing fundraising activities and events for support of Shifaa operations and projects .●	Coordinating joint work between Shifaa and SJC. ●	Planning training workshops for members and volunteers. Recruited 110 volunteer members. Raised $78,846 for medical operations. Raised $33,462 for scholarships for university students. Raised more than $200,000 for Syrian refugees. Organized events for 582 orphans. Organized fundraising events. Produced 5 musical plays in collaboration with LAU as fundraising events for scholarships for needy students. Attended press conferences and TV talk shows representing Shifaa NGO.",
    },
    {
      yearRange: "April 1999 - Dec 2001",
      title: "PRODUCT MANAGER",
      place: "SADCO, Medical company- Beirut, Lebanon",
      desc: "Managing a team of nine Medical representatives, Building and implementing marketing strategy for Pharmaceutical products, MERZ Laboratories, Germany.Preparing marketing promotional and advertising material. Conducting training courses and seminars for doctors and health care providers in major Hospitals in collaboration with consultants from principle companies.Preparing course material and training logistics for new hires and provide support for new product launches. Conducting performance evaluations for subordinate staff while setting Key Performance Indicators (KPIs) for Sales activity and customer service satisfaction",
    },
    {
      yearRange: "1998 to March 1999",
      title: "CUSTOMER SERVICE COORDINATOR",
      place: "Fattal,  Medical company- Beirut, Lebanon ",
      desc: "Coordinating sales activities. Providing technical support to customers and doctors for products of CONVATEC, subsidiary of Bristol Meyer Squibb, USA.",
    },
  ];

  const skills = [
    {
      name: "Agreeableness: ready or willing to agree",
      percent: 65,
    },
    {
      name: "Conscientousness: marked by wary caution or prudence",
      percent: 95,
    },
    {
      name: "Extraversion: A personality trait characterized by a preference to engage socially with others",
      percent: 80,
    },
    {
      name: "Openness: having no barrier towards new ideas.",
      percent: 70,
    },
    {
      name: "Stress Tolerance: The ability to withstand difficult and chronic situations.",
      percent: 60,
    } 
  ];

  return (
    <section id="resume" className="section">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">Resume</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          NLP coach Sawsan Itani's resume
        </h2>
        {/* Heading end*/}
        <div className="row g-5 mt-5">
          {/* My Education */}
          <div className="col-lg-6 wow fadeInUp">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Education</h2>
            <div className="border-start border-2 border-primary ps-3">
              {educationDetails.length > 0 &&
                educationDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
          {/* My Experience */}
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.2s">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Experience</h2>
            <div className="border-start border-2 border-primary ps-3">
              {experienceDetails.length > 0 &&
                experienceDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* My Skills */}
        <h2 className="text-7 fw-600 mb-4 pb-2 mt-5 wow fadeInUp">The five elements of personality</h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div className="col-md-6 wow fadeInUp" key={index}>
                <p className="fw-500 text-start mb-2">
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div className="progress progress-sm mb-4">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        <p className="text-center mt-5 wow fadeInUp">
          <a
            className="btn btn-outline-dark shadow-none rounded-0"
            href={"https://www.simplypsychology.org/big-five-personality.html"}
            download
          >
            Learn More
          </a>
        </p>
      </div>
    </section>
  );
};

export default Resume;
